import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { device } from "helpers/device";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "assets/properties/kgv/logo.svg";
import Image from "assets/properties/kgv/kgv-01.jpg";

const Wrapper = styled.div`
	background-color: #fef6e5;
	display: flex;
	position: relative;
	flex-direction: column;
	gap: 48px;
	padding-bottom: 40px;

	@media ${device.laptop} {
		flex-direction: row-reverse;
		padding-top: 100px;
	}
`;

const TextSection = styled.div`
	margin-left: 20px;
	margin-right: 20px;
	font-weight: 500;

	.spaced {
		font-size: 16px;
		letter-spacing: 3px;
	}
	.title {
		margin-top: 21px;
		font-size: 32px;
		letter-spacing: 1px;
	}
	.info {
		max-width: 290px;
		margin-top: 26px;
		font-size: 14px;	
	}

	@media ${device.laptop} {
		flex-basis: 40%;
	}
`;

const ImageSection = styled.div`
	position: relative;
	margin-top: 49px;
	padding-left: 56px;
	.svg-wrapper {
		position: absolute;
		top: 13px;
		left: 13px;
	}
	@media ${device.laptop} {
		flex-basis: 60%;
		margin-top: 0px;
		padding-left: 0px;

		.svg-wrapper {
			top: -57px;
			left: 203px;
		}

		svg {
			height: 140px;
			width: 140px;
		}
	}
`;
const Button = styled.div`
	cursor: pointer;
	margin-top: 31px;
	background-color: #003202;
	height: 42px;
	width: 263px;
	color: white;
	font-weight: 500;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const HeroSection = ({ buttonClick }) => {
	const videoRef = useRef(null);
	const [scrollY, setScrollY] = useState(0);

	useEffect(() => {
		const handleLoadedData = () => {
			if (videoRef.current) {
				videoRef.current.play();
				document.dispatchEvent(new Event("click"));
			}
		};

		if (videoRef.current) {
			videoRef.current.addEventListener("loadeddata", handleLoadedData);
		}

		return () => {
			if (videoRef.current) {
				videoRef.current.removeEventListener("loadeddata", handleLoadedData);
			}
		};
	}, []);

	const { t } = useTranslation();

	// Set up a scroll event listener
	useEffect(() => {
		const handleScroll = () => {
			setScrollY(window.scrollY);
		};

		window.addEventListener("scroll", handleScroll);

		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	// Calculate the rotation angle based on scroll position
	const rotation = scrollY % 360; // Modulo 360 to keep rotation within 0-359 degrees

	const handleClick = () => {
		buttonClick();
	};

	return (
		<Wrapper>
			<ImageSection>
				<div className='svg-wrapper' style={{ transform: `rotate(${rotation}deg)` }}>
					<Logo />
				</div>
				<img width={"100%"} src={Image} />
			</ImageSection>
			<TextSection>
				<p className='spaced'>TIL LEJE</p>
				<p className='title'>Nyopførte liebhaverlejligheder på Frederiksberg</p>
				<Button onClick={handleClick}>SE PLANTEGNINGER OG PRISER</Button>
				<p className='info'>
					<strong>Åbent hus med tilmelding</strong> søndag d. 1. september fra kl. 10:00 til 12:00
				</p>
			</TextSection>
		</Wrapper>
	);
};

export default HeroSection;
