import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import styled from "styled-components";
import { device } from "helpers/device";
import { useTranslation } from "react-i18next";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";
import Loader from "components/Loader";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as Logo } from "assets/properties/kgv/logo.svg";

const customStyles = {
	overlay: {
		zIndex: 1000,
		backgroundColor: "rgb(56 67 54 / 90%)",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		outline: "none",
	},
	content: {
		position: "relative",
		border: "none",
		height: "100%",
		backgroundColor: "#003202",
		maxWidth: "800px",
		minHeight: "300px",
		minWidth: "380px",
		outline: "none",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "80vw",
		padding: "24px",
		boxSizing: "border-box",
	},
};

const Wrapper = styled.div``;

const ModalContentContainer = styled.div`
	max-width: 600px;
	min-width: 340px;
	display: flex;
	flex-direction: column;
	align-items: center;

	svg {
		height: 140px;
		width: 140px;
		fill: #fff;
		// stroke: #fff;

		path {
			fill: #fff;
			// stroke: #fff;
		}
	}
`;

const Title = styled.h1`
	font-size: 40px;
	color: #fff;
	font-weight: 700;
	text-align: center;
`;

const Description = styled.p`
	font-size: 24px;
	color: #fff;
	font-weight: 400;
	text-align: center;
	padding-bottom: 40px;
`;

const ConfirmedDescription = styled.p`
	font-size: 20px;
	color: #fff;
	font-weight: 400;
	text-align: center;
`;

const InputWrapper = styled.div`
	width: -webkit-fill-available;
	margin-left: 20px;
	margin-right: 20px;
	display: flex;
	flex-direction: column;
	margin-top: 24px;

	label {
		color: #fff;
	}
	input {
		color: #3e5042;
		border: none;
		font-size: 16px;
		padding-top: 4px;
		padding-bottom: 4px;
		border-bottom: 1px solid grey;
		outline: none;
		text-transform: capitalize;
	}
	input#email {
		text-transform: unset;
	}
	input#email::first-word {
		text-transform: capitalize;
	}
	&.row {
		flex-direction: row;

		input {
			padding: 0;
			margin: 0;
			margin-right: 10px;
		}
		a {
			color: #fff;
			text-decoration: underline;
		}
	}

	@media ${device.laptop} {
		input#privacy {
			height: 30px;
			width: 30px;
		}
	}
`;

const CloseButton = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;

	svg {
		height: 60px;
		width: 60px;
		fill: #fff;
		stroke: #fff;

		path {
			fill: #fff;
			stroke: #fff;
		}
	}
`;

const Button = styled.div`
	cursor: pointer;
	margin-top: 50px;
	color: ${({ inversed }) => (inversed ? "#003202" : "#fff")};
	background-color: ${({ inversed }) => (inversed ? "#fff" : "#003202")};
	height: 42px;
	padding-left: 20px;
	width: fit-content;
	padding-right: 20px;

	font-weight: 500;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin-bottom: 20px;

	&.inverse {
		color: #fff;
		background-color: #003202;
	}
`;

const BookShowcasingButton = (props) => {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [sendingMessage, setSendingMessage] = useState(false);
	const [messageSent, setMessageSent] = useState(false);
	const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);

	const { t } = useTranslation();

	const closeModal = () => setModalIsOpen(false);

	const onNameChange = (e) => {
		setName(e.target.value);
	};

	const onEmailChange = (e) => {
		setEmail(e.target.value);
	};

	const onPhoneNumberChange = (e) => {
		setPhoneNumber(e.target.value);
	};

	const onPrivacyPolicyChange = (e) => {
		setPrivacyPolicyAccepted(e.target.value);
	};

	initializeApp({
		apiKey: "AIzaSyCoIfG8XEBXDaGy9ntdckgrf-IFRpKaRaY",
		authDomain: "norsepm-dk-fac16.firebaseapp.com",
		projectId: "norsepm-dk-fac16",
	});

	const db = getFirestore();

	async function writeFormSubmit() {
		setSendingMessage(true);
		if (name && email && phoneNumber) {
			try {
				await addDoc(collection(db, "contact-formula"), {
					to: 'udlejning@norsepm.dk',
					message: {
						subject: "Anmodning om fremvisning på Kong Georgs Vej",
						html: `Navn: ${name}</br>Email: ${email}</br>Telefon: ${phoneNumber}</br>Url: ${window.location.href}`,
					},
				});
				setName("");
				setEmail("");
				setPhoneNumber("");
				setSendingMessage(false);
				setMessageSent(true);
			} catch (e) {
				console.error("Error adding document: ", e);
				alert("Et teknisk problem opstod. Beklager ulejligheden.");
			}
		} else {
			alert("Alle felter skal udfyldes");
		}
	}

	return (
		<Wrapper>
			<Button inversed={props.inversed} onClick={() => setModalIsOpen(true)}>
				Tilmeld dig
			</Button>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				style={customStyles}
				className={"interestlist-modal"}
				ariaHideApp={false}
			>
				<CloseButton onClick={closeModal}>
					<CloseIcon />
				</CloseButton>
				<ModalContentContainer>
					{!sendingMessage && !messageSent && (
						<>
							<Title>Tilmeld dig</Title>
							<Logo />
							{/* <Description>Få besked om nye boliger og undgå at gå glip af drømmeboligen.</Description> */}
							<InputWrapper>
								<label htmlFor='name'>{t("contact.name")}*</label>
								<input value={name} onChange={onNameChange} id='name' type='text' />
							</InputWrapper>
							<InputWrapper>
								<label htmlFor='email'>{t("contact.email")}*</label>
								<input value={email} onChange={onEmailChange} id='email' type='text' />
							</InputWrapper>
							<InputWrapper>
								<label htmlFor='phone'>{t("contact.phone")}*</label>
								<input value={phoneNumber} onChange={onPhoneNumberChange} id='phone' type='text' />
							</InputWrapper>
							<InputWrapper className='row'>
								<input
									type='checkbox'
									id='privacy'
									checked={privacyPolicyAccepted}
									onChange={onPrivacyPolicyChange}
								/>
								<label htmlFor='privacy'>
									Jeg accepterer Norse Property Managements{" "}
									<a href='/privacy-policy' target='_blank'>
										privatlivspolitik
									</a>
									.
								</label>
							</InputWrapper>
							<Button inversed onClick={(e) => writeFormSubmit(e)}>
								Send forespørgsel
							</Button>
						</>
					)}
					{sendingMessage && !messageSent && (
						<>
							<Loader />
						</>
					)}
					{!sendingMessage && messageSent && (
						<>
							<Title>Besked modtaget.</Title>
							<ConfirmedDescription>
								Vi har fået din henvendelse og vender tilbage hurtigst muligt.
							</ConfirmedDescription>
							<Button onClick={() => setModalIsOpen(false)}>Afslut</Button>
						</>
					)}
				</ModalContentContainer>
			</Modal>
		</Wrapper>
	);
};

export default BookShowcasingButton;
