import React, { useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { device } from "helpers/device";
import parkingImage from "assets/images/parking.png";

const Wrapper = styled.div`
	width: 100%;
	max-width: 1120px;
	margin: auto;
	background: white;
	position: relative;
	@media ${device.laptop} {
	}

	.slick-slide img {
		margin: auto;
		object-fit: cover;
	}

	.slick-next:before,
	.slick-prev:before {
		opacity: 0.75;
	}

	.sliderView {
		.slick-prev {
			left: 5px;
			z-index: 10;
			@media ${device.laptop} {
				left: 25px;
			}
		}
		.slick-next {
			right: 35px;
			z-index: 10;
			@media ${device.laptop} {
				right: 75px;
			}
		}
		.slick-prev:before,
		.slick-next:before {
			font-size: 50px;
			@media ${device.laptop} {
				font-size: 75px;
			}
		}
		.slick-slide img {
			width: 100%;
			max-height: 60vh;

			&.floorplan {
				max-height: unset;
			}
		}
	}

	.sliderNavigation {
		display: none;
		@media ${device.laptop} {
			display: block;
		}
	}

	.sliderNavigation .slick-slide img {
		height: 140px;
		width: 210px;
		cursor: pointer;
	}

	.buttons-container {
		position: absolute;
		height: 50px;
		z-index: 50;
		right: 16px;
		bottom: 164px;
		display: flex;
		flex-direction: row;
		display: none;
		@media ${device.laptop} {
			display: flex;
			justify-content: space-between;
		}
		div {
			cursor: pointer;
			padding: 10px;
			background-color: ${(p) => p.theme.colors.ascent.secondary};
			line-height: 2;
			color: white;
			font-weight: 700;
			font-size: 16px;
		}
		div.floorplan {
			margin-left: 16px;
			&.hidden {
				display: none;
			}
		}
		div.images {
			&.hidden {
				display: none;
			}
		}
	}
`;

const ReservedOverlay = styled.div`
	pointer-events: none;
	position: absolute;
	z-index: 50;
	width: 100%;
	height: 100%;
	text-align: center;
	background-color: #d7d9d778;
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 1120px;

	div {
		background-color: #384336;
		height: fit-content;
		z-index: 1000;
		padding: 16px;
		color: white;
		font-size: 24px;
	}
`;

const CarouselSection = (props) => {
	const { classification, images, floorplans, isReserved } = props;
	const { t } = useTranslation();
	const [slider1, setSlider1] = useState();
	const [slider2, setSlider2] = useState();

	var settings1 = {
		adaptiveHeight: true,
		arrows: true,
	};

	var settings2 = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 6,
		slidesToScroll: 1,
		swipeToSlide: true,
		focusOnSelect: true,
	};
	const mappedFloorplans = floorplans.map((f) => {
		return {
			id: f.id,
			img: f.url,
			class: "floorplan",
		};
	});

	let mappedImages, mappedImagesSmall;
	if (classification === "Other") {
		mappedImages = [{
			id: 1,
			img: parkingImage,
			class: "image",
		}];
		mappedImagesSmall = mappedImages;
	} else {
		mappedImages = images.map((i) => {
			return {
				id: i.id,
				img: i.original,
				class: "image",
			};
		});
		mappedImagesSmall = images.map((i) => {
			return {
				id: i.id,
				img: i.alternatives[0]?.url,
				class: "smallImage",
			};
		});
	}

	const combined = [...mappedImages, ...mappedFloorplans];
	const combinedSmall = [...mappedImagesSmall, ...mappedFloorplans];

	const handleFloorplansClick = () => {
		slider1.slickGoTo(mappedImages.length);
	};
	const handleImagesClick = () => {
		slider1.slickGoTo(0);
	};

	return (
		<Wrapper>
			{isReserved && (
				<ReservedOverlay>
					<div>{t("general.reserved")}</div>
				</ReservedOverlay>
			)}
			<div className='buttons-container'>
				<div
					className={`images ${
						floorplans.length === 0 ? "hidden" : ""
					}`}
					onClick={handleImagesClick}
				>
					{t("tenancy.carouselSection.images")}
				</div>
				<div
					className={`floorplan ${
						floorplans.length === 0 ? "hidden" : ""
					}`}
					onClick={handleFloorplansClick}
				>
					{t("tenancy.carouselSection.floorplans")}
				</div>
			</div>
			<Slider
				className='sliderView'
				asNavFor={slider2}
				ref={(slider1) => setSlider1(slider1)}
				{...settings1}
			>
				{combined.map((e) => (
					<div key={e.id}>
						<img src={e.img} className={e.class} />
					</div>
				))}
			</Slider>
			{combinedSmall.length > 1 && (
				<Slider
					className='sliderNavigation'
					asNavFor={slider1}
					ref={(slider2) => setSlider2(slider2)}
					{...settings2}
				>
					{combinedSmall.map((e) => (
						<div key={e.id}>
							<img src={e.img} />
						</div>
					))}
				</Slider>
			)}
		</Wrapper>
	);
};

CarouselSection.propTypes = {
	classification: PropTypes.string,
	images: PropTypes.array,
	floorplans: PropTypes.array,
	isReserved: PropTypes.bool,
};

CarouselSection.defaultProps = {
	classification: "",
	images: [],
	floorplans: [],
	isReserved: false,
};

export default CarouselSection;
