import React from "react";
import styled from "styled-components";
import TenancyCard from "components/TenancyCard";
import ParkingCard from "components/ParkingCard";
import PropTypes from "prop-types";
import MapView from "./MapView";
import Loader from "components/Loader";
import { device } from "helpers/device";

const GridViewWrapper = styled.div`
	position: relative;
	width: 100%;
	max-width: 960px;
	margin: auto;
	padding: 16px 16px 50px 16px;

	box-sizing: border-box;
	display: grid;
	grid-template-columns: 1fr;
	grid-column-gap: 19px;
	grid-row-gap: 19px;

	@media ${device.laptop} {
		padding: 120px 0px;
		padding-left: 80px;
		padding-right: 80px;
		grid-template-columns: 1fr 1fr 1fr;
		box-sizing: content-box;
		background-color: white;
	}
`;

const NoResultsWrapper = styled.div`
	text-align: center;
	padding: 100px;
`;

const TenanciesSection = (props) => {
	const {
		filteredTenancies,
		allTenancies,
		loading,
		floorplanImageMode,
		gridViewMode,
		selectedPlace,
	} = props;

	if (loading) {
		return (
			<GridViewWrapper>
				<Loader />
			</GridViewWrapper>
		);
	}

	if (gridViewMode) {
		return (
			<>
				{filteredTenancies.length === 0 && (
					<NoResultsWrapper>
						No results matched your search.
					</NoResultsWrapper>
				)}
				<GridViewWrapper>
					{filteredTenancies.map((t) => {
						if (t.classification === "Residential") {
							return (
								<TenancyCard
									key={t.id}
									name={t.title}
									images={t.images}
									price={t.monthlyRent.value}
									availableFrom={t.availableFrom}
									rooms={t.rooms}
									size={t.size.value}
									id={t.id}
									street={t.address.street}
									location={`${t.address.zipCode} ${t.address.city}`}
									floorplanImageMode={floorplanImageMode}
									floorplan={t.floorPlans[0]?.url}
									isReserved={t.state === "Reserved"}
									openHouses={t.openHouses}
								/>
							);
						} else if (t.classification === "Other") {
							return (
								<ParkingCard
									key={t.id}
									name={t.title}
									images={t.images}
									price={t.monthlyRent.value}
									availableFrom={t.availableFrom}
									rooms={t.rooms}
									size={t.size.value}
									id={t.id}
									street={t.address.street}
									location={`${t.address.zipCode} ${t.address.city}`}
									floorplanImageMode={floorplanImageMode}
									floorplan={t.floorPlans[0]?.url}
									isReserved={t.state === "Reserved"}
									openHouses={t.openHouses}
								/>
							);
						}
					})}
				</GridViewWrapper>
			</>
		);
	} else {
		return (
			<MapView
				filteredTenancies={filteredTenancies}
				allTenancies={allTenancies}
				selectedPlace={selectedPlace}
				floorplanImageMode={floorplanImageMode}
			/>
		);
	}
};

TenanciesSection.propTypes = {
	loading: PropTypes.bool,
	tenancies: PropTypes.arrayOf(PropTypes.object),
};

export default TenanciesSection;
